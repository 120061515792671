import { CustomLink } from 'src/components/common/CustomLink'

import * as S from './styles'
import type { IColumn as IInstItemProps } from '../types'

const InstItem: FCC<IInstItemProps> = ({ title, links }) => {
  return (
    <S.ItemContent>
      <div>
        <h2>{title}</h2>
        <ul>
          {links.map(({ name, to, televendas }) => {
            if (televendas) {
              return (
                <S.TelevendasContent key={name}>
                  {televendas.text}
                  <span>{televendas.telNumber}</span>
                </S.TelevendasContent>
              )
            }

            if (!to) {
              return (
                <S.SendEmailSpan key={name}>
                  <span>{name}</span>
                </S.SendEmailSpan>
              )
            }

            return (
              <li key={name}>
                <CustomLink to={to}>{name}</CustomLink>
              </li>
            )
          })}
        </ul>
      </div>
    </S.ItemContent>
  )
}

export default InstItem
