import { useMemo, useState } from 'react'
import { stringToSanitizedHtml } from 'src/utils/stringToSanitizedHtml'
import NavBar from 'src/components/common/Institutional/CallCenter'
import ResponsiveImage from 'src/components/ui/Image/ResponsiveImage'
import { draftToHtml } from 'src/utils/draftToHtml'

import type { InstitutionalTextProps } from './types'
import * as S from './styles'

const InstitutionalText: FCC<InstitutionalTextProps> = ({
  text,
  title,
  children,
  header,
  headerImage,
}) => {
  const parsedText = useMemo(() => draftToHtml(text), [text])

  const [isOpen, setIsOpen] = useState(false)

  return (
    <S.Container>
      <S.LeftMenu open={isOpen}>
        <S.MobileMenu open={isOpen} onClick={() => setIsOpen(!isOpen)}>
          Outras Opções
        </S.MobileMenu>
        <NavBar />
      </S.LeftMenu>
      <S.TextContainer>
        {(!!header || !!headerImage) && (
          <S.Header>
            {header}
            {headerImage && !!headerImage.desktop && (
              <ResponsiveImage {...headerImage} />
            )}
          </S.Header>
        )}

        <S.TextWrapper>
          {title && <h1>{title}</h1>}
          {stringToSanitizedHtml(parsedText)}
          {children}
        </S.TextWrapper>
      </S.TextContainer>
    </S.Container>
  )
}

export default InstitutionalText
