import { mediaMax } from 'src/styles/utils/mediaMax'
import styled, { css } from 'styled-components'

export const Figure = styled.figure<{ hasMobile?: boolean }>`
  display: flex;
  img {
    height: auto;
    width: 100%;

    ${({ hasMobile }) =>
      hasMobile &&
      css`
        &.mobile {
          display: none;
        }
        ${mediaMax(
          css`
            &.mobile {
              display: flex;
            }
            &.desktop {
              display: none;
            }
          `,
          767
        )}
      `}
  }
`
