// InstItem

import styled from 'styled-components'

export const ItemContent = styled.li`
  h2 {
    font-weight: 700;
    color: ${({ theme }) => theme.colors.primary_0};
    font-size: 18px;
    font-family: 'Baloo';
  }

  ul {
    max-width: 270px;
    margin-bottom: 24px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  li {
    display: flex;
    flex-direction: column;
    margin-top: 12px;
  }

  a {
    font-weight: 400;
    font-size: 14px;
    font-family: 'Baloo 2', sans-serif;
    color: ${({ theme }) => theme.colors.grey_2};
    transition: 0.3s;
  }

  a:hover {
    color: ${({ theme }) => theme.colors.primary_0};
    text-decoration: underline;
  }
`
export const TelevendasContent = styled.li`
  font-weight: 400;
  font-size: 14px;
  font-family: 'Baloo 2', sans-serif;
  color: ${({ theme }) => theme.colors.grey_2};

  span {
    margin-top: 12px;
    font-size: 14px;
  }
`

export const SendEmailSpan = styled.li`
  font-weight: 400;
  font-size: 14px;
  font-family: 'Baloo 2', sans-serif;
  color: ${({ theme }) => theme.colors.grey_2};
`
