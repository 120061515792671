// InstColumn

import { mediaMax } from 'src/styles/utils/mediaMax'
import styled, { css } from 'styled-components'

export const AsideContent = styled.aside`
  padding-right: 20px;

  ${mediaMax(
    css`
      padding-right: 10px;
      height: 100vh;
      padding-left: 16px;
      padding-top: 10px;

      overflow: auto;
    `,
    1024
  )}

  ul {
    /* height: 100vh; */

    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    list-style: none;

    ${mediaMax(
      css`
        flex-direction: column;
      `,
      470
    )}
  }
`
