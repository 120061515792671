import { CustomLink } from 'src/components/common/CustomLink'

import Image from './Image'
import * as S from './styles'
import type { ResponsiveImageProps } from './types'
import { isImage } from './types'

const ResponsiveImage: FCC<ResponsiveImageProps> = ({
  alt,
  desktop,
  mobile,
  href,
  style,
}) => {
  return (
    <CustomLink to={href ?? null} style={href ? style : {}}>
      <S.Figure hasMobile={!!mobile} style={!href ? style : {}}>
        <Image
          src={isImage(desktop) ? desktop.src : desktop}
          width={isImage(desktop) ? desktop.width ?? 1366 : 1366}
          height={isImage(desktop) ? desktop.height ?? 200 : 200}
          alt={alt}
          noFactors
          srcSet=""
          className="desktop"
          loading="eager"
        />
        {mobile && (
          <Image
            src={isImage(mobile) ? mobile.src : mobile}
            width={isImage(mobile) ? mobile.width ?? 370 : 370}
            height={isImage(mobile) ? mobile.height ?? 130 : 130}
            alt={alt}
            noFactors
            srcSet=""
            className="mobile"
            loading="eager"
          />
        )}
      </S.Figure>
    </CustomLink>
  )
}

export default ResponsiveImage
