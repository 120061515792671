import * as S from './styles'
import InstColumn from './InstColumn'

const cmsInfo = [
  {
    title: 'Antes da Compra',
    links: [
      {
        name: 'Como Navegar no Site',
        to: '/institucionais/AntesCompra/ComoNavegar',
      },
      {
        name: 'FAQ',
        to: '/institucionais/AntesCompra/faq',
      },
      {
        name: 'Compra Segura',
        to: '/institucionais/Antescompra/CompraSegura',
      },
      {
        name: 'Política de Privacidade',
        to: '/institucionais/AntesCompra/PoliticaPrivacidade',
      },
      {
        name: 'LGPD',
        to: '/institucionais/AntesCompra/lgpd',
      },
      {
        televendas: {
          text: 'Televendas:',
          telNumber: '(11) 4810-6810',
        },
      },
    ],
  },
  {
    title: 'Comprando',
    links: [
      {
        name: 'Alterar Dados Cadastrais',
        to: '/institucionais/Comprando/AlterarDadosCadastrais',
      },
      {
        name: 'Alterar Endereço de Entrega',
        to: '/institucionais/Comprando/AlterarEnderecoEntrega',
      },
      {
        name: 'Formas de Pagamento',
        to: '/institucionais/Comprando/FormasPagamento',
      },
      {
        name: 'Serviço de Entrega',
        to: '/institucionais/Comprando/ServicoEntrega',
      },
      {
        name: 'Produtos não Disponíveis',
        to: '/institucionais/Comprando/ProdutosNaoDisponiveis',
      },
      {
        name: 'Sugestões de Produtos',
        to: '/institucionais/Comprando/SugestaoProdutos',
      },
    ],
  },
  {
    title: 'Após a Compra',
    links: [
      {
        name: 'Acompanhe Seu Pedido',
        to: '/', // TODO LOGIN
      },
      {
        name: 'Prazo de Entrega',
        to: '/institucionais/PosCompra/PrazoEntrega',
      },
      {
        name: 'Trocas e Devoluções',
        to: '/institucionais/PosCompra/TrocasDevolucoes',
      },
      {
        name: 'Dados de Compras Divergentes',
        to: '/institucionais/PosCompra/DadosDivergentes',
      },
      {
        name: 'Dificuldade na Cobrança: Cartão',
        to: '/institucionais/PosCompra/DificuldadeCobrancaCartao',
      },
      {
        name: 'Dificuldade na Cobrança: Boleto',
        to: '/institucionais/PosCompra/DificuldadeCobrancaBoleto',
      },
      {
        name: 'Cancelamento',
        to: '/institucionais/PosCompra/Cancelamento',
      },
    ],
  },
  {
    title: 'Institucional',
    links: [
      {
        name: 'Fale Conosco',
        to: '/institucionais/faleconosco',
      },
      {
        name: 'Envie-nos um e-mail de contato, com suas sugestões e críticas.',
      },
      {
        name: 'Quem Somos',
        to: '/institucionais/AntesCompra/QuemSomos',
      },
      {
        name: 'Nossas Lojas',
        to: '/institucionais/AntesCompra/NossasLojas',
      },
    ],
  },
  {
    title: 'Links Úteis',
    links: [
      {
        name: 'Iniciativas COVID-19',
        to: '/institucionais/linksUteis/iniciativaCovid',
      },
    ],
  },
]

const CallCenter: FCC = () => {
  return (
    <S.ContainerCenterInst>
      <InstColumn data={cmsInfo} />
    </S.ContainerCenterInst>
  )
}

export default CallCenter
