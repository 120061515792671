import * as S from './styles'
import InstItem from '../InstItem'
import type { IColumnsProps } from '../types'

const InstColumn: FCC<IColumnsProps> = ({ data }) => {
  return (
    <S.AsideContent>
      <div>
        <ul>
          {data.map((column) => (
            <InstItem
              key={column.title}
              title={column.title}
              links={column.links}
            />
          ))}
        </ul>
      </div>
    </S.AsideContent>
  )
}

export default InstColumn
