import { containerCenter } from 'src/styles/utils/containerCenter'
import { mediaMax } from 'src/styles/utils/mediaMax'
import styled, { css } from 'styled-components'

export const Container = styled.div`
  ${containerCenter}
  display: flex;
  margin-top: 20px;
`

export const TextContainer = styled.section`
  margin-bottom: 65px;
  width: 100%;
  display: flex;
  flex-direction: column;

  ${mediaMax(
    css`
      flex-direction: column;
    `,
    470
  )}

  ${mediaMax(
    css`
      width: 100%;
    `,
    1024
  )}

  h1 {
    font-weight: 700;
    font-size: 26px;
    font-family: 'Baloo', sans-serif;
    color: ${({ theme }) => theme.colors.primary_0};
    margin-bottom: 16px;
    line-height: 28px;
    text-transform: uppercase;
  }

  h2,
  h3 {
    /* font-weight: 400;
    font-size: 22px;
    font-family: 'Baloo', sans-serif;
    color: ${({ theme }) => theme.colors.primary_0};
    margin-bottom: 12px;
    line-height: 28px; */

    float: left;
    width: 100%;
    margin: 17px 0px 7px 0px;
    letter-spacing: 0px;
    color: ${({ theme }) => theme.colors.grey_2};
    font-weight: 700;
    font-size: 1.125rem;
    text-transform: uppercase;
    text-decoration: underline;

    ${mediaMax(
      css`
        margin-bottom: 4px;
      `,
      470
    )}
  }

  p {
    /* font-weight: 400;
    font-size: 14px;
    color: ${({ theme }) => theme.colors.grey_2};
    line-height: 19.6px;
    margin-bottom: 16px; */

    float: left;
    width: 100%;
    margin: 0px 0px 15px 0px;
    line-height: 150%;
    color: ${({ theme }) => theme.colors.grey_2};
    font-weight: 400;
    font-size: 1rem;

    ${mediaMax(
      css`
        margin-bottom: 14px;
      `,
      470
    )}
  }

  b,
  strong {
    font-weight: 700;
    /* font-size: 14px; */
    /* color: ${({ theme }) => theme.colors.orange_0}; */
    line-height: 18.2px;
  }

  a {
    color: ${({ theme }) => theme.colors.primary_0};
  }

  ul {
    margin-bottom: 16px;
    list-style-type: unset;
    padding-left: 15px;

    ${mediaMax(
      css`
        margin-bottom: 14px;
      `,
      470
    )}

    li {
      font-weight: 400;
      font-size: 14px;
      color: ${({ theme }) => theme.colors.grey_2};
      line-height: 18.2px;
      list-style: unset;
    }
  }
`

export const LeftMenu = styled.div<{ open: boolean }>`
  width: 25%;
  ul {
    flex-direction: column;
  }

  @media screen and (max-width: 1024px) {
    position: fixed;
    top: 0;
    /* padding-top: 10px; */
    background-color: white;
    overflow: visible;
    /* overflow-x: visible; */
    z-index: 6;
    width: 65%;
    left: -65%;
    transition: 0.4s;

    ${({ open }) =>
      open
        ? css`
            left: 0;
          `
        : ''}
  }

  a:hover {
    color: purple;
    font-weight: 600;
    text-decoration: none;
    color: ${({ theme }) => theme.colors.primary_0};
  }
`
export const MobileMenu = styled.div<{ open: boolean }>`
  cursor: pointer;
  position: fixed;
  left: 0;
  /* top: 225px; */
  background: #361328;
  width: 50px;
  height: 50px;
  display: none;
  align-items: center;
  justify-content: center;
  transition: 0.4s;

  flex-direction: column;
  color: white;
  font-size: 10px;
  text-align: center;
  line-height: 1;

  &::before {
    content: '>>';
    font-family: 'Baloo';
    font-size: 28px;
    color: ${({ theme }) => theme.colors.orange_0};
    display: flex;
    align-items: center;
    justify-content: center;
  }

  ${mediaMax(
    css`
      display: flex;
      top: 230px;
    `,
    1024
  )}

  ${mediaMax(
    css`
      display: flex;
      top: 225px;
    `,
    768
  )}

  ${({ open }) =>
    open
      ? css`
          left: 65%;
          &::before {
            content: '<<';
          }
        `
      : css`
          left: 0;
        `}
`

export const TextWrapper = styled.div`
  width: 100%;
`

export const Header = styled.header`
  margin-bottom: 50px;
`

export const Figure = styled.figure<{ hasMobile?: boolean }>`
  display: flex;
  img {
    height: auto;
    width: 100%;

    ${({ hasMobile }) =>
      hasMobile &&
      css`
        &.mobile {
          display: none;
        }
        ${mediaMax(
          css`
            &.mobile {
              display: flex;
            }
            &.desktop {
              display: none;
            }
          `,
          767
        )}
      `}
  }
`
