// SideBar

import styled, { css } from 'styled-components'
import { containerCenter } from 'src/styles/utils/containerCenter'
import { mediaMin } from 'src/styles/utils/mediaMin'

export const ContainerCenterInst = styled.div`
  ${mediaMin(
    css`
      ${containerCenter}
    `,
    1025
  )}
`
