import type { PageProps } from 'gatsby'
import { graphql } from 'gatsby'
import { BreadcrumbJsonLd, GatsbySeo } from 'gatsby-plugin-next-seo'
import { mark } from 'src/sdk/tests/mark'
import type {
  InstitutionalPageQueryQuery,
  InstitutionalPageQueryQueryVariables,
} from '@generated/graphql'
import { Image } from 'src/components/ui/Image'
import InstitutionalText from 'src/components/sections/InstitutionalText'
import { stringToSanitizedHtml } from 'src/utils/stringToSanitizedHtml'
import Newsletter from 'src/components/sections/Newsletter'

const Page: FCC<
  PageProps<InstitutionalPageQueryQuery, InstitutionalPageQueryQueryVariables>
> = (props) => {
  const {
    data: { site },
    location: { host, pathname },
    params: { slug },
  } = props

  const title = 'Iniciativas COVID-19'

  const canonical =
    host !== undefined
      ? `https://${host}/${slug}`
      : slug
      ? `/${slug}`
      : pathname

  return (
    <>
      {/* SEO */}
      <GatsbySeo
        title={title}
        titleTemplate={
          site?.siteMetadata?.titleTemplate &&
          title.endsWith(site.siteMetadata.titleTemplate.replace('%s', ''))
            ? '%s'
            : site?.siteMetadata?.titleTemplate ?? ''
        }
        description={site?.siteMetadata?.description ?? ''}
        canonical={canonical}
        language="pt-br"
        openGraph={{
          type: 'website',
          title,
          description: site?.siteMetadata?.description ?? '',
        }}
      />
      <BreadcrumbJsonLd
        itemListElements={[
          {
            item: '/institucionais/linksUteis/iniciativaCovid',
            name: title,
            position: 1,
          },
        ]}
      />

      {/*
        Sections: Components imported from '../components/sections' only.
        Do not import or render components from any other folder in here.
      */}

      <InstitutionalText
        title="INICIATIVAS COVID-19"
        header={
          <Image
            src="https://miess.vtexassets.com/arquivos/ids/205894/bannerdesk.jpg"
            width={1366}
            height={200}
            alt="Banner Desktop"
            loading="eager"
            style={{ marginBottom: '50px', width: '100%', height: 'auto' }}
          />
        }
      >
        {stringToSanitizedHtml(`
          <h2>Fique por dentro das novas medidas, mediante a pandemia.</h2>

          <br /><br />
          <h3>》 A retirada de pedidos está funcionando normalmente? </h3>

          <p>Sim, com algumas restrições. Como medida de segurança, as retiradas dos pedidos estão sendo realizadas
            <strong>mediante ao agendamento</strong> com a nossa equipe de atendimento, pelo telefone <strong>(11)
              4810-6810</strong> assim que o <strong>pagamento é aprovado</strong>. </p>

          <p>Aderimos o agendamento de retirada para evitar possíveis aglomerações em nosso escritório, prezando pela saúde e
            bem estar de colaboradores e clientes. </p>

          <p> Atenderemos <strong>um cliente a cada meia hora</strong>, das 8h às 17:30h.</p>

          <p>Todas as medidas de higienização para entrega de pedidos estão sendo tomadas, para que você receba com segurança.
          </p>

          <p><strong>IMPORTANTE:</strong> Não atenderemos retiras sem agendamento prévio. </p>

          <br />
          <h3>》 O prazo de entrega aumentou? </h3>

          <p>Devido aos novos protocolos de segurança, estendemos temporariamente os prazos para despachar os pedidos para
            <strong>5 dias úteis</strong> após a confirmação do pagamento. Para consultar o andamento do seu pedido é só acessar
            a opção “<a href="https://www.miess.com.br/_secure/account#/orders">Meus Pedidos</a>” no site. </p>

          <p>Já para saber o prazo de entrega para sua região, é só digitar seu CEP nas páginas de produtos ou em sua própria
            sacola de compras que será exibido a agilidade de entrega que será aplicada no pedido. </p>

          <p>No momento, as entregas realizadas pelos Correios podem sofrer alterações de prazos ou atrasos sem aviso prévio,
            devido a situação da pandemia. </p>

          <p>Mas fique tranquilo! Caso o pedido sofra qualquer evento que implique diretamente na entrega, iremos agir
            diretamente com os Correios assim que a opção de aberturado do PI (Pedido de Informação) estiver disponível. </p>

          <p>Fique atento as atualizações de informações diretamente pelo site dos Correios, <a
              href="http://correios.com.br/coronavirus/boletim/">clicando aqui</a>.</p>


          <p>Você encontra as <a href="/Vitrine-Transacional">condições e prazos aqui</a>, lembrando que retirada de pedidos e a
            Entrega Express possuem prazos diferenciados. </p>
          <br />
          <h3>》 Posso me contaminar com os produtos comprados? </h3>

          <p>Estamos tomando todas as medidas possíveis de segurança e de higiene, segundo as recomendações da ANVISA. Até o
            momento, não houve nenhuma informação sobre uma possível contaminação pelos produtos, mas recomendamos que higienize
            a parte externa das embalagens e produtos assim que os receber e, após manuseá-las, que também higienize as suas
            mãos e superfícies que entraram em contato com os itens. </p>
          <br />
          <h3>》 Posso trocar meus produtos comprados? </h3>

          <p>Sim. As trocas estão ocorrendo normalmente, você possui <strong>7 dias</strong> a partir do recebimento do pedido
            para efetuar a sua troca, desde que o produto esteja lacrado, na embalagem original e sem indícios de uso ou
            avarias. No caso de trocas por defeito de fabricação, esse prazo se estende, sendo <strong>30 dias</strong>
            corridos, a partir do recebimento. </p>

          <p>Você encontra o <a href="/institucionais/PosCompra/TrocasDevolucoes">regulamento completo de trocas aqui</a>, leia
            atentamente e depois entre em contato com a nossa equipe pelo e-mail <strong>sac@miess.com.br</strong></p>
          <br />
          <h3>》Qual é o tempo de resposta do SAC, WhatsApp e Redes Sociais? </h3>
          O prazo de retorno das analistas de atendimento são de até <strong>48 horas úteis</strong>, que não se aplicam para a
          resolução de reclamações ou procedimentos especiais. Caso necessite de agilidade no atendimento, <strong>entre em
            contato diretamente pelo telefone (11) 4810-6810</strong>.
        `)}
      </InstitutionalText>

      <Newsletter />
    </>
  )
}

/**
 * This query is run during SSG
 * */
export const query = graphql`
  query IniciativaCovidPageQuery {
    site {
      siteMetadata {
        titleTemplate
        title
        description
      }
    }
  }
`
Page.displayName = 'Page'

export default mark(Page)
