import type React from 'react'

export interface ImageData {
  src: string
  alt: string
  href?: string
  width?: number
  height?: number
}

interface ResponsiveImage {
  src: string
  width?: number
  height?: number
}

export interface ResponsiveImageData {
  desktop: string | ResponsiveImage
  mobile?: string | ResponsiveImage
  alt: string
  href?: string
}

export interface ResponsiveImageProps extends ResponsiveImageData {
  style?: React.CSSProperties
}

export const isImage = (payload: any): payload is ResponsiveImage => payload.src
